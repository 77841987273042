import Observer from '@researchgate/react-intersection-observer';
import { navigate } from 'gatsby';
import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import GlobalStyle from '../assets/styles/GlobalStyle';
import Spacer from '../atoms/Spacer';
import Text from '../atoms/Text';
import { solutionsItems } from '../config/cardsContents';
import { ENTITIES, NAV_ITEMS, SECTIONS_IDS, URLS } from '../config/constants';
import Layout, { MyContext } from '../layout/LayoutContainer';
import '../locales/i18n';
import BecomePartnerSection from '../organisms/BecomePartnerSection/BecomePartnerSection';
import CTABanner from '../organisms/CTABanner/CTABanner';
import CTABannerMainText from '../organisms/CTABanner/CTABannerMainText';
import DescriptionText from '../organisms/CTABanner/CTABannerDescriptionText';
import CTABannerContentWithBg from '../organisms/CTABanner/CTABannerContentWithBg';
import Footer, { MobileFooter } from '../organisms/Footer/Footer';
import Header from '../organisms/Header';
import SectionHeader from '../organisms/SectionHeader/SectionHeader';
import SectionHeaderMainText from '../organisms/SectionHeader/SectionHeaderMainText';
import SectionHeaderSubText from '../organisms/SectionHeader/SectionHeaderSubText';
import styled, { css } from 'styled-components';
import { mobile, phone } from '../utils/media';
import SolutionCard from '../organisms/SolutionCard/SolutionCard';
import SolutionCardImage from '../organisms/SolutionCard/SolutionCardImage';
import SolutionCardActions from '../organisms/SolutionCard/SolutionCardActions';
import Button from '../atoms/Button';
import { COLORS } from '../assets/styles/variables';
import ForwardLink from '../molecules/ForwardLink';
import { HOME_ADVANTAGES_CARDS } from '../config/cardsContents';
import AdvantageCard from '../organisms/AdvantageCard/AdvantageCard';

const CTABannerMobile = styled(CTABanner)`
  ${phone(css`
    min-height: 400px;
  `)}

  ${mobile(css`
    min-height: 350px;
  `)}
`;

const SolutionsCardWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  max-width: 1440px;
  padding: 0 3%;
  margin: 0 auto;
  ${phone(css`
    grid-template-columns: 1fr;
  `)}
  ${mobile(css`
    gap: 20px;
  `)}
`;

const AdvantagesCardsWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 40px;
  max-width: 1440px;
  padding: 0 3%;
  margin: 40px auto;
  ${mobile(css`
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  `)}
  ${phone(css`
    grid-template-columns: 1fr;
  `)}
`;

class Index extends React.Component {
  state = {
    [SECTIONS_IDS.dealer]: { visited: false, visibility: 'hidden' },
    [SECTIONS_IDS.agronomist]: { visited: false, visibility: 'hidden' },
    [SECTIONS_IDS.farmer]: { visited: false, visibility: 'hidden' },
    [SECTIONS_IDS.dairyFarmer]: { visited: false, visibility: 'hidden' },
    [SECTIONS_IDS.opinions]: { visited: false, visibility: 'hidden' },
    [SECTIONS_IDS.partners]: { visited: false, visibility: 'hidden' },
    [SECTIONS_IDS.solutions]: { visited: false, visibility: 'hidden' },
  };

  handleSectionChange = (event) => {
    const targetId = event && event.target.id;
    this.setState({
      [targetId]: {
        visibility: event.isIntersecting ? 'visible' : 'hidden',
        visited: window.scrollY > 0,
      },
    });
  };

  getSectionClassName = (sectionId) => {
    return `fading-box ${this.state[sectionId].visibility} ${this.state[sectionId].visited && 'visited'}`;
  };

  onLogin = () => window.open(URLS.login, '_BLANK');
  onPlatformSignup = () => window.open(URLS.signup, '_BLANK');
  onSignup = (persona) => {
    if (persona === ENTITIES.dealer) {
      navigate(NAV_ITEMS.contactUs.linkTo, {
        state: { persona },
      });
    } else if (persona === ENTITIES.agronomist || persona === ENTITIES.farmer) {
      window.open(URLS.signup + '?areaOfInterest=farmer', '_BLANK');
    } else if (persona === ENTITIES.dairyFarmer) {
      window.open(URLS.signup + '?areaOfInterest=breeder', '_BLANK');
    } else {
      window.open(URLS.signup, '_BLANK');
    }
  };

  render() {
    const { t } = this.props;

    const HeaderCon = (props) => {
      return (
        <MyContext.Consumer>{(value) => <Header loginCb={this.onLogin} {...value} {...props} />}</MyContext.Consumer>
      );
    };
    return (
      <Layout loginCb={this.onLogin} {...this.props}>
        <HeaderCon />

        <CTABannerMobile height={450} bgImage={'home/solution-hero.png'} bgPosition={'center'} overlay={false}>
          <div>
            <CTABannerContentWithBg>
              <CTABannerMainText block size="32px" transform="none" weight="normal">
                {t('app:home:cta:title')}
              </CTABannerMainText>
              <Spacer height="15px" />
              <DescriptionText size="22px" align="left" transform="none" weight="normal">
                {t('app:home:cta:description')}
              </DescriptionText>
            </CTABannerContentWithBg>
          </div>
        </CTABannerMobile>

        <section id="designed-in-collaboration">
          <div>
            <SectionHeader>
              <SectionHeaderMainText transform="none" lineHeight="1.2" color={COLORS.greyText}>
                {t('app:home:designed_in_collaboration')}
              </SectionHeaderMainText>
              <SectionHeaderSubText transform="none" lineHeight="1.2" color={COLORS.greyText}>
                <Trans i18nKey={'app:home:designed_in_collaboration_subtext'}>
                  <Text weight={'bold'} />
                </Trans>
              </SectionHeaderSubText>
            </SectionHeader>
          </div>
        </section>

        <Observer rootMargin="0% 0% -5%" onChange={this.handleSectionChange}>
          <SolutionsCardWrapper id="solutions" className={this.getSectionClassName('solutions')}>
            {solutionsItems &&
              solutionsItems.map((item, i) => (
                <SolutionCard key={i}>
                  <SectionHeaderMainText
                    transform="none"
                    align="center"
                    weight="bold"
                    size="32px"
                    lineHeight="1.2"
                    color={COLORS.greyText}
                  >
                    {t(`app:home:${item.id}`)}
                  </SectionHeaderMainText>
                  <SolutionCardImage image={`home/${item.image}`} />
                  <SectionHeaderMainText
                    transform="none"
                    align="center"
                    weight="bold"
                    size="24px"
                    lineHeight="1.2"
                    color={COLORS.greyText}
                  >
                    {t(`app:home:${item.id}_title`)}
                  </SectionHeaderMainText>
                  <SectionHeaderSubText block color={COLORS.greyText} lineHeight="1.4">
                    {t(`app:home:${item.id}_description`)}
                  </SectionHeaderSubText>
                  <SolutionCardActions>
                    <ForwardLink linkTo={item.linkTo}>
                      <Button bgColor={COLORS.primary}>{t('app:home:learn_more')}</Button>
                    </ForwardLink>
                  </SolutionCardActions>
                </SolutionCard>
              ))}
          </SolutionsCardWrapper>
        </Observer>
        {/* Professionists cards */}

        {/* {professionistItems &&
          professionistItems.map((item, i) => (
            <Observer key={i} rootMargin="0% 0% -5%" onChange={this.handleSectionChange}>
              <div id={SECTIONS_IDS[item.id]} className={this.getSectionClassName(SECTIONS_IDS[item.id])}>
                <ShowcaseCard
                  reverse={!!(i % 2)}
                  image={`home/${item.image}`}
                  title={t(`app:home:${SECTIONS_IDS[item.id]}`)}
                  description={t(`app:home:${SECTIONS_IDS[item.id]}_desc`)}
                  mainCb={() => this.onSignup(item.id)}
                  mainCbLabel={t(`app:home:${SECTIONS_IDS[item.id]}_cta_btn`)}
                  forwardLink={item.linkTo}
                  forwardLinkLabel={t('app:home:learn_more')}
                  bgPosition={item.id === 'dealer' ? '80% 0' : 'center'}
                />
              </div>
            </Observer>
          ))} */}

        {/* What professionals are saying */}
        {/*<Observer rootMargin="0% 0% -20%" onChange={this.handleSectionChange}>*/}
        {/*  <div id={SECTIONS_IDS.opinions} className={this.getSectionClassName(SECTIONS_IDS.opinions)}>*/}
        {/*    <OpinionsSection />*/}
        {/*  </div>*/}
        {/*</Observer>*/}

        <AdvantagesCardsWrapper>
          {HOME_ADVANTAGES_CARDS.map((item) => {
            return (
              <AdvantageCard
                key={item.id}
                title={t(`app:home:advantages:${item.id}:title`)}
                text={t(`app:home:advantages:${item.id}:text`)}
                icon={item.image}
              />
            );
          })}
        </AdvantagesCardsWrapper>

       {/* Developer and become partner will be eventually added later */}
       {/* <BecomePartnerSection /> */}

        {/* Our partners */}
        {/*<Observer rootMargin="0% 0% -20%" onChange={this.handleSectionChange}>*/}
        {/*  <div id={SECTIONS_IDS.partners} className={this.getSectionClassName(SECTIONS_IDS.partners)}>*/}
        {/*    <PartnersSection signupCb={this.onSignup} />*/}
        {/*  </div>*/}
        {/*</Observer>*/}

        <Footer />
        <MobileFooter />

        <GlobalStyle />
      </Layout>
    );
  }
}

Index.propTypes = {};

export default withTranslation()(Index);
